import type { LoaderFunctionArgs } from "@remix-run/node";
import type { MetaFunction } from "@remix-run/node";
import { Form, redirect } from "@remix-run/react";
import { getSession } from "~/library/sessions";

export const meta: MetaFunction = () => {
  return [
    { title: "Welten" }
  ];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const session = await getSession(
    request.headers.get("Cookie")
  );

  if (!session.has("authenticated")) return null

  return redirect("/vacatures");
};

export default function Index() {
  return (
    <>
      <div className="u-inline-flex u-gap-x-2 u-items-center u-p-4">
        <span className="u-w-3 u-h-3 u-rounded-full u-bg-[red] u-block u-animate-pulse"></span>
        Unauthorized
      </div>
    </>
  )
}

